import Image1 from '../images/home/vastech-hairback.webp';
import Image2 from '../images/home/vastech-security.webp';
import CableImage from '../images/residential/vastech-cable.webp';
import NetworkImage from '../images/residential/vastech-network.webp';

let AboutData = {
    hero: {
        title: "VASTech Solutions: We’re Your Tech Guy.",
        subheader: "About",
        body: 'We install residential and commercial AV systems in Buffalo, New York, and beyond.',
    },

    numberSection: [
        {
            number: "15",
            body: 'Years of Experience',
        },
        {
            number: "200",
            body: 'Businesses Served',
        },
        {
            number: "300+",
            body: 'Residences Served',
        },
        {
            number: "1,700+",
            body: 'Cameras Installed',
        },
        {
            number: "2,500+",
            body: 'TVs Installed',
        },
        {
            number: "4,000+",
            body: 'Projects Completed',
        },
    ],
    aboutSection: [
        {
            title: "The big picture.",
            body: "You already know that today’s technology is faster. Systems are smarter. And staying connected is key. So, how are you keeping up?"
        },
        {
            title: "Meet Vic.",
            body: "VASTech Solutions is owned and operated by Vic Scaccia II, an AV installations expert with 15 years of experience in the field. "
        },
        {
            title: "Inspired to empower.",
            body: "With a bachelor’s degree in business administration, Vic supplements his on-the-job experience with a formal skill set to understand your challenges, implement solutions, and teach you how to use your technology. "
        },
        {
            title: "It’s easy. Honest.",
            body: "Don’t have the time, energy, or know-how to set up new technology? No worries. You’ve got a guy for that. With Vic from VASTech Solutions, you can take full advantage of the latest tech—easily, often, and with purpose."
        },
        {
            title: "Get it done. Keep it going.",
            body: "Can you really rely on those geeky dudes from that big box store? Probably not. For constant support and high-quality service, call or text someone you can count on: Vic from VASTech Solutions."
        },
    ],
    areasServedSection: [
        {
            body: "Buffalo, NY",
        },
        {
            body: "Rochester, NY",
        },
        {
            body: "Erie, PA",
        },
        {
            body: "Pittsburgh, PA",
        },
        {
            body: "Raleigh, NC",
        }
    ]
};

export { AboutData };
